<template>
  <div
    v-if="isShowing"
    class="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
  >
    <div class="mx-auto">
      <div class="p-0.5 rounded-full">
        <div class="p-1 bg-white rounded-full">
          <embed
            src="/image/v-loading-animation.svg"
            height="120"
            width="120"
            loading="lazy"
            crossorigin="anonymous"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  showInstantly: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const isShowing = ref(props.showInstantly);
setTimeout(() => {
  isShowing.value = true;
}, 500);
</script>
